import { useEffect } from "react";

const useClickAway = (ref, callback) => {
  // Custom hook to handle when a user clicks outside of the component that uses this hook
  useEffect(() => {
    const handleClickAway = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event); // Trigger the custom logic when click-away event occurs
      }
    };

    document.addEventListener("mousedown", handleClickAway);

    // Cleanup the listener to prevent memory leaks
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [ref, callback]); // Depend on ref and callback to rebind listener if they change
};

export default useClickAway;
