import { XMarkIcon } from "@heroicons/react/24/outline";
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
} from "react";
import {
  DatasetFilterableAttribute,
  DatasetAttributes,
  DatasetStatus,
  DatasetVendor,
  IDataset,
  IFilters,
} from "../../types/datasets";
import FilterCategory from "./FilterCategory";
import { parseDatasetTag } from "../../utils";
import { IN_HOUSE_DATASETS_STATUS_VALUE } from "../../constants";
import useClickAway from "../../hooks/clickAwayHook";

interface IDatasetFilter {
  isFilterOpen: boolean;
  setIsFilterOpen: (bool: boolean) => void;
  datasets: IDataset[];
  setFilters: Dispatch<SetStateAction<IFilters>>;
  filters: IFilters;
  updateFilterQueryParam: (queryString: string) => void;
}

const DatasetFilter: React.FC<IDatasetFilter> = ({
  isFilterOpen,
  setIsFilterOpen,
  datasets,
  setFilters,
  filters,
  updateFilterQueryParam,
}) => {
  const componentRef = useRef();

  useClickAway(componentRef, (event: SyntheticEvent) => {
    if (event.target.id !== "filter-button") setIsFilterOpen(false);
  });

  const renderFilterPanel = () => {
    const generateFilterOptions = (
      datasetField: DatasetFilterableAttribute,
    ): Set<string> => {
      const options = new Set<string>();

      for (const { id: _, attributes: dataset } of datasets) {
        const datasetFieldValue: string | boolean = dataset[datasetField];

        if (datasetFieldValue == null) continue;

        switch (datasetField) {
          case "isFeatured":
          case "isInHouse":
            options.add(datasetFieldValue ? "Yes" : "No");
            break;

          case "vendor":
          case "serviceCategory":
          case "serviceSubcategory":
            options.add(datasetFieldValue as string);
            break;

          case "sectors":
          case "geographies":
          case "assetClasses":
            dataset[datasetField].forEach((value: string) =>
              options.add(value),
            );
            break;

          default:
            throw new Error("invalid filtering parameter");
        }
      }

      return options;
    };

    const filterCategories = [
      {
        category: generateFilterOptions("vendor"),
        name: "Vendor",
        datasetFieldName: "vendor",
      },
      {
        category: generateFilterOptions("serviceCategory"),
        name: "Category",
        datasetFieldName: "serviceCategory",
      },
      {
        category: generateFilterOptions("serviceSubcategory"),
        name: "Subcategory",
        datasetFieldName: "serviceSubcategory",
      },
      {
        category: generateFilterOptions("sectors"),
        name: "Sectors",
        datasetFieldName: "sectors",
      },
      {
        category: generateFilterOptions("geographies"),
        name: "Geography",
        datasetFieldName: "geographies",
      },
      {
        category: generateFilterOptions("assetClasses"),
        name: "Asset",
        datasetFieldName: "assetClasses",
      },
      {
        category: generateFilterOptions("isInHouse"),
        name: "In-House",
        datasetFieldName: "isInHouse",
      },
      {
        category: generateFilterOptions("isFeatured"),
        name: "Featured",
        datasetFieldName: "isFeatured",
      },
    ];

    return filterCategories.map((filterCategory) => {
      return (
        <FilterCategory
          category={filterCategory.category}
          name={filterCategory.name}
          datasetFieldName={filterCategory.datasetFieldName}
          setFilters={setFilters}
          filters={filters}
          updateFilterQueryParam={updateFilterQueryParam}
          key={filterCategory.name}
        />
      );
    });
  };

  return (
    <div
      ref={componentRef}
      className={`fixed top-[100px] right-0 w-[300px] bg-white z-10 transition-transform duration-1000 px-5 border-l border-l-gray-light shadow-lg pr-2 overflow-y-scroll overflow-x-hidden scrollbar-none ${
        isFilterOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="flex justify-between mt-6 font-bold text-lg items-center">
        <div className="">Filters</div>
        <XMarkIcon
          className="h-5 cursor-pointer"
          onClick={() => setIsFilterOpen(false)}
        />
      </div>
      <nav className="mt-6">{renderFilterPanel()}</nav>
    </div>
  );
};

export default DatasetFilter;
