import React, { useEffect, useState } from "react";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import DatasetFilter from "../../components/DatasetFilter";
import DatasetTIle from "../../components/DatasetTile/index";
import { navigate } from "gatsby";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import {
  IFilters,
  DatasetAttributes,
  StrapiUnit,
  DatasetId,
  DatasetFilterableAttribute,
} from "../../types/datasets";

import useURLQueryParameter from "../../hooks/queryParamHook";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import BannerComponent from "../../components/Banner";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SelectDropdown from "../../components/SelectDropdown";
import { XCircleIcon } from "@heroicons/react/24/solid";

enum SortOptions {
  Featured = "Featured",
  Alphabetical = "A-Z",
  ReverseAlphabetical = "Z-A",
}

const sortOptions = [
  { name: "Featured", value: SortOptions.Featured },
  { name: "A-Z", value: SortOptions.Alphabetical },
  { name: "Z-A", value: SortOptions.ReverseAlphabetical },
];

const tagPrefixes = new Map([
  ["vendor", "Vendor"],
  ["serviceCategory", "Category"],
  ["serviceSubcategory", "Subcategory"],
  ["sectors", "Sector"],
  ["geographies", "Geography"],
  ["assetClasses", "Asset"],
  ["isInHouse", "In-House"],
  ["isFeatured", "Featured"],
]);

const createTagText = (category: string, option: string) => {
  return tagPrefixes.get(category) + ": " + option;
};

const getLastModifiedDate = (dataset: StrapiUnit<DatasetAttributes>) => {
  return dataset.attributes.updatedAt ?? dataset.attributes.createdAt;
};

const datasetPassesFilters = (
  dataset: StrapiUnit<DatasetAttributes>,
  filters: IFilters,
): boolean => {
  // any attribute without values is an attribute that hasn't been filtered on.
  const prunedFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([_, value]) => Object.keys(value).length > 0,
    ),
  );

  // initialize a mapping from attribute names to their resolved
  const resolvedFilters = Object.fromEntries(
    Object.keys(prunedFilters).map((key: string) => [key, false]),
  );

  // if no filters are specified, allow all datasets.
  if (Object.keys(resolvedFilters).length === 0) return true;

  // we apply an OR condition for filters in the same group, and an AND condition across groups, i.e.
  // specifying two vendors and one asset class means "allow if vendor in (A, B) AND asset class in D".
  for (const attribute of Object.keys(resolvedFilters)) {
    const value = dataset.attributes[attribute as DatasetFilterableAttribute];

    switch (attribute as keyof DatasetAttributes) {
      case "isFeatured":
      case "isInHouse":
        if (
          (prunedFilters[attribute]["Yes"] && value) ||
          (prunedFilters[attribute]["No"] && !value)
        )
          resolvedFilters[attribute] = true;

        break;

      default:
        if (Array.isArray(value))
          resolvedFilters[attribute] = value.some(
            (value) => prunedFilters[attribute][value] === true,
          );
        else
          resolvedFilters[attribute] =
            prunedFilters[attribute][value as string] === true;

        break;
    }
  }

  return Object.entries(resolvedFilters).every(([key, value]) => value);
};

const defaultFilters = (): IFilters => ({
  vendor: {},
  serviceCategory: {},
  serviceSubcategory: {},
  sectors: {},
  geographies: {},
  assetClasses: {},
  isInHouse: {},
  isFeatured: {},
});
const setInitialFilters = (filterQueryParam: string) => {
  const filters = defaultFilters();

  if (!filterQueryParam) {
    return filters;
  } else {
    const existingFilters: [
      { name: keyof DatasetAttributes; values: string[] },
    ] = JSON.parse(decodeURIComponent(filterQueryParam));
    for (const category of existingFilters) {
      for (const val of category.values) {
        filters[category.name][val] = true;
      }
    }
    return filters;
  }
};

const Datasets: React.FC = () => {
  const [datasets, setDatasets] = useState<StrapiUnit<DatasetAttributes>>([]);
  const [datasetHeader, setDatasetHeader] = useState<string>("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { accessToken, clearIdentity } = useAuthContext();

  const [searchQueryParam, updateSearchQueryParam] =
    useURLQueryParameter("searchTerm");
  const [filterQueryParam, updateFilterQueryParam] =
    useURLQueryParameter("filters");
  const [searchTerm, setSearchTerm] = useState<string>(searchQueryParam || "");
  const [filters, setFilters] = useState<IFilters>(
    setInitialFilters(filterQueryParam),
  );

  const [sortSelection, setSortSelection] = useState(SortOptions.Featured);

  const { data, isLoading, error } = useNorthstar(
    "/api/catalog-datasets",
    accessToken,
    {
      method: "get",
      params: {
        fields: [
          "name",
          "shortDescription",
          "isInHouse",
          "isFeatured",
          "createdAt",
          "updatedAt",
        ],
        sort: ["id"],
        populate: [
          "vendor",
          "serviceCategory",
          "serviceSubcategory",
          "sectors",
          "geographies",
          "assetClasses",
        ],
        ...(searchQueryParam == ""
          ? {}
          : {
              "filters[$or][0][name][$contains]": searchQueryParam,
              "filters[$or][1][shortDescription][$contains]": searchQueryParam,
              "filters[$or][2][description][$contains]": searchQueryParam,
            }),
      },
    },
  );

  useEffect(() => {
    let ignore = true;

    if (error?.status === 401 && !ignore) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  useEffect(() => {
    if (data?.data) {
      setDatasets(data.data as StrapiUnit<DatasetAttributes>[]);
    }
  }, [
    JSON.stringify(
      data?.data?.map((element: StrapiUnit<DatasetAttributes>) => element.id),
    ),
  ]);

  useEffect(() => {
    if (!searchQueryParam && !filterQueryParam)
      setDatasetHeader(
        `All Datasets ${isLoading ? "" : `(${datasetsToRender().length})`}`,
      );
    else
      setDatasetHeader(
        `Search Results ${searchQueryParam ? "for " + searchQueryParam : ""} ${
          isLoading ? "" : `(${datasetsToRender().length})`
        }`,
      );
  });

  const sorted = (
    datasets: StrapiUnit<DatasetAttributes>[],
  ): StrapiUnit<DatasetAttributes>[] => {
    switch (sortSelection) {
      case SortOptions.Featured:
        let featured: StrapiUnit<DatasetAttributes>[] = [];
        const nonfeatured: StrapiUnit<DatasetAttributes>[] = [];

        datasets.forEach((ds) => {
          if (ds.attributes.isFeatured) featured.push(ds);
          else nonfeatured.push(ds);
        });

        nonfeatured.sort((a, b) => {
          if (getLastModifiedDate(a) < getLastModifiedDate(b)) return 1;
          return -1;
        });

        featured = featured.concat(nonfeatured);

        return featured;

      case SortOptions.ReverseAlphabetical:
        datasets.sort((a, b) => {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase())
            return 1;
          return -1;
        });
        return datasets;

      // case SortOptions.Alphabetical:
      default:
        datasets.sort((a, b) => {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase())
            return -1;
          return 1;
        });
        return datasets;
    }
  };

  const datasetsToRender = () => {
    const sortedDatasets = sorted(datasets);

    return sortedDatasets.filter((dataset: StrapiUnit<DatasetAttributes>) =>
      datasetPassesFilters(dataset, filters),
    );
  };

  const navToPage = (datasetId: number) => {
    navigate(`/datasets/${datasetId}`);
  };

  const renderDatasets = () => {
    return (
      <div className="mt-4 mb-6">
        <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-5 xl:gap-4 2xl:gap-5 3xl:gap-6">
          {datasetsToRender().map((dataset) => {
            return (
              <DatasetTIle
                title={dataset.attributes.name}
                description={dataset.attributes.shortDescription}
                tag={dataset.attributes.serviceSubcategory}
                vendorName={dataset.attributes.vendor}
                key={dataset.attributes.name + dataset.id}
                isFeatured={dataset.attributes.isFeatured}
                onClick={() => {
                  navToPage(dataset.id);
                }}
                lastModifiedDate={
                  dataset.attributes.updatedAt ?? dataset.attributes.createdAt
                }
              />
            );
          })}
        </ul>
      </div>
    );
  };

  const updateFilterQueryParams = (newFilters: IFilters) => {
    const filters = [];
    for (const category in newFilters) {
      if (Object.values(newFilters[category]).length > 0)
        filters.push({
          name: category,
          values: Object.keys(newFilters[category]).map((option) => option),
        });
    }

    updateFilterQueryParam(
      filters.length ? encodeURIComponent(JSON.stringify(filters)) : "",
    );
  };

  const renderFilterTags = () => {
    const output = [];

    for (const category in filters) {
      for (const option of Object.keys(filters[category]).sort()) {
        output.push(
          <div
            className="flex items-center text-sm my-1 mr-4 px-4 py-2 border border-gray-200 bg-gray-200 text-gray-800"
            key={category + option}
          >
            <div className="mr-1">{createTagText(category, option)}</div>
            <XCircleIcon
              className="h-5 cursor-pointer text-gray-400 hover:text-gray-500"
              onClick={() => {
                removeFilterTag(category, option);
              }}
            />
          </div>,
        );
      }
    }
    return output;
  };

  const removeFilterTag = (category: string, value: string) => {
    const newFilters = { ...filters };
    delete newFilters[category][value];
    updateFilterQueryParams(newFilters);
    setFilters(newFilters);
  };

  const returnToDefaultView = () => {
    updateSearchQueryParam("");
    setSearchTerm("");
    updateFilterQueryParam("");
    navigate("/datasets");
    setFilters(defaultFilters());
  };

  const handleSearchBarChange = (value: string) => {
    setSearchTerm(value);
    if (!value && searchQueryParam) {
      updateSearchQueryParam("");
    }
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortSelection(event.target.value as SortOptions);
  };

  return (
    <NorthstarPageLayout title="Datasets" className="overflow-y-scroll">
      <>
        <Spinner active={isLoading} />
        <>
          <BannerComponent
            title="Datasets"
            description="The datasets page provides a searchable collection of commercial data that we have encountered. This search tool was custom-built for our portfolio companies to identify opportunities to integrate new data into products and operations."
          />
          <div>
            <div className="mb-3 flex items-center">
              <ExclamationCircleIcon className="h-5 text-orange mr-1" />
              <div className="text-sm font-semibold">
                The records on this page are strictly for data discovery and do
                not provide access to the data.
              </div>
            </div>
            <div className="flex items-center ">
              <div className="w-full md:w-2/3 lg:w-3/4 xl:w-[55rem] md:pr-3 lg:pr-4">
                <TextField
                  id="dataset-search"
                  placeholder="Search Datasets"
                  label=""
                  autoComplete="off"
                  variant="outlined"
                  onChange={(e) => {
                    handleSearchBarChange(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? updateSearchQueryParam(searchTerm)
                      : null
                  }
                  value={searchTerm}
                  fullWidth
                  sx={{
                    ".MuiOutlinedInput-root": {
                      borderRadius: "0px",
                      bgcolor: "white",
                    },
                  }}
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start">
                    //     <SearchIcon />
                    //   </InputAdornment>
                    // ),
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear search"
                          onClick={() => {
                            returnToDefaultView();
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-full md:w-1/3 lg:w-1/4 xl:w-[19rem] mt-3 xs:mt-4 md:mt-0">
                <SelectDropdown
                  label="Sort by"
                  value={sortSelection}
                  onChange={handleSortChange}
                  options={sortOptions}
                />
              </div>
            </div>
            <div className="my-3 font-semibold text-lg">{datasetHeader}</div>
            <div className="mb-6 flex items-center flex-wrap">
              <div
                className="inline-flex items-center mr-4 my-1 px-4 py-2 border border-blue text-blue font-medium text-sm hover:bg-blue hover:text-white cursor-pointer"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                id="filter-button"
              >
                Filters
              </div>
              {renderFilterTags().length > 0 ? (
                <div
                  className="inline-flex items-center mr-4 my-1 px-4 py-2 border border-blue text-blue font-medium text-sm hover:bg-blue hover:text-white cursor-pointer"
                  onClick={() => {
                    setFilters(defaultFilters());
                    updateFilterQueryParam("");
                  }}
                >
                  Clear All
                </div>
              ) : (
                <></>
              )}
              {renderFilterTags()}
            </div>
            <div className="pb-6 flex flex-row flex-wrap gap-5">
              {!isLoading && renderDatasets()}
            </div>
            {
              <DatasetFilter
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
                datasets={datasets}
                filters={filters}
                setFilters={setFilters}
                updateFilterQueryParam={updateFilterQueryParam}
              />
            }
          </div>
        </>
      </>
    </NorthstarPageLayout>
  );
};

export default Datasets;
