import { StarIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import Tag, { TagStyle } from "../Tag";
import Tooltip from "../Tooltip";
import { DateTime } from "luxon";

interface TileProps {
  title: string;
  description?: string;
  tag: string;
  vendorName: string;
  status: string;
  isFeatured: boolean;
  onClick?: () => void;
  lastModifiedDate: string;
}

const Tile: React.FC<TileProps> = ({
  title,
  description,
  tag,
  vendorName,
  isFeatured,
  onClick,
  lastModifiedDate,
}) => {
  return (
    <div
      className="flex flex-col w-full h-full px-4 py-3 bg-white hover:shadow-lg cursor-pointer font-roboto"
      onClick={onClick}
    >
      <div className="flex mt-0.5 items-center justify-between min-h-14">
        <div className="flex-1 text-3xl font-bold text-orange">
          {vendorName}
        </div>
        <div className="flex">
          {isFeatured && (
            <div>
              <Tooltip text="Featured" width="90px">
                <StarIcon className="h-6 text-orange" />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="line-clamp-2 text-2xl font-semibold text-gray-900">
        {title}
      </div>
      <div className="mt-2 xs:mt-3 text-gray-800">
        Last Modified :{" "}
        {DateTime.fromISO(lastModifiedDate).toFormat("dd MMM yyyy")}
      </div>
      <div className="my-2 xs:my-3 pb-1 line-clamp-3 text-sm text-gray-600">
        {description}
      </div>
      <div className="grow h-0"></div>
      <div className="h-8 mb-1.5 flex items-center">
        {tag && <Tag id={title} name={tag} tagStyle={TagStyle.SQUARE} />}
      </div>
    </div>
  );
};

export default Tile;
